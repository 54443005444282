<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button defaultHref="app/categories"></ion-back-button>
    </ion-buttons>
    <ion-title>Lista cursos</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="real-estate-listing-content">
  <div class="listing-item" *ngFor="let item of listing?.cursos">

    <ion-row class="image-row">
      <ion-col size="12">
        <a class="image-anchor" (click)="navigateToVideos(item.slug)">
          <app-image-shell [display]="'cover'" animation="spinner" class="item-picture" [src]="item.picture">
            <app-aspect-ratio [ratio]="{w:365, h:125}">
            </app-aspect-ratio>
          </app-image-shell>
        </a>
      </ion-col>
    </ion-row>
    <ion-row class="description-row">
      <ion-col size="12" class="details-col">
        <ion-row class="details-wrapper">
          <ion-col>
            <h4 class="item-price">
              <app-text-shell [data]="item.nombre"></app-text-shell>
            </h4>
            <p class="item-address">
              <app-text-shell [data]="item.descripcion"></app-text-shell>
            </p>
          </ion-col>

        </ion-row>
      </ion-col>


    </ion-row>
  </div>
</ion-content>